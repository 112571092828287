@use '../../../../../styles' as *;

.topSection {
  @include standartBlock($direction: row);
  align-items: stretch;

  &__suggest {
    @include standartBlock($width: 73.5%);
    gap: 11px;

    &__header {
      @include standartBlock($direction: row, $justify: flex-start);
      gap: 16px;

      &__title {
        margin: 0;
        font-size: 20px;
        font-weight: 700;
      }
    }

    &__list {
      &__content {
        height: 213px;
        @include standartBlock($align: flex-start);
        background: #f1f0fc;
        border-radius: 19px;
        padding: 24px;
        gap: 16px;
        z-index: 1;

        &__title {
          color: #413b7d;
          font-size: 15px;
          font-weight: 700;
          margin: 0;
          line-height: normal;
        }

        ul {
          position: relative;
          display: flex;
          flex-direction: column;
          gap: 12px;

          li {
            position: relative;
            color: #413b7d;
            font-size: 14px;
            font-weight: 500;
            line-height: normal;
            padding: 0 0 0 21px;

            &::before {
              content: '\2022';
              position: absolute;
              display: flex;
              align-items: center;
              justify-content: center;
              width: 10px;
              height: 10px;
              left: 5px;
              top: 0;
              transform: translate(0, 25%);
            }
          }
        }
      }
    }
  }

  &__opportunity {
    @include standartBlock($width: 24.15%, $align: flex-start);

    gap: 11px;

    &__title {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      gap: 8px;

      svg {
        width: 20px;
        height: 20px;
      }
    }

    &__card {
      @include standartBlock($align: flex-start);
      flex: 1 0 auto;
      padding: 14px 16px;
      border-radius: 16px;
      background: #fff;
      box-shadow: 0px 8px 68.5px 0px rgba(0, 0, 0, 0.11);

      &__title {
        margin: 0;
        color: #5e636e;
        font-size: 14px;
        font-weight: 400;
        line-height: normal;
      }

      &__ravenue {
        color: #1f2228;
        font-size: 18px;
        font-weight: 700;
        line-height: normal;
        margin: 16px 0 10px 0;
      }

      &__data {
        @include standartBlock($align: flex-start);
        gap: 4px;

        &__title {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          margin: 0;
          color: #5e636e;
          font-size: 12px;
          font-weight: 500;
          line-height: normal;
          gap: 6px;
        }

        &__total {
          position: relative;
          width: 100%;
          height: 24px;
          border-radius: 11px;
          background: #f7f7f8;

          &__leftData {
            position: absolute;
            left: 16px;
            top: 50%;
            transform: translate(0, -50%);
            color: #fff;
            font-size: 14px;
            font-weight: 700;
          }

          &__rightData {
            position: absolute;
            right: 16px;
            top: 50%;
            transform: translate(0, -50%);
            color: #464a53;
            font-size: 14px;
            font-weight: 600;
          }

          &__progress {
            height: 100%;
            background: #9189e9;
            border-radius: 11px;
          }
        }
      }
    }
  }
}
