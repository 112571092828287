/* Dark Mode Prevention */
.rdg {
  background-color: white !important;
  color: black !important;
}

.rdg .rdg-row {
  background-color: white !important;
  color: black !important;
  border: 1px solid #ddd !important;
}

.rdg .rdg-header-row {
  background-color: #f5f5f5 !important;
}

.rdg .rdg-row {
  background-color: white !important;
}

.rdg .rdg-row:hover {
  background-color: #fbfbfb !important;
}
/* End Dark Mode Prevention */

.rdg select{
  background-color: inherit !important; 
}
.rdg select:focus {
  outline: none;
}

/* Review Budget Specific */
.datasheet-grp .rdg-cell{
    border-left-width: 0 !important;
    border-right-width: 0 !important;
    border-top-width: 0 !important;
}
.datasheet-grp .rdg{
    border-width: 1px;
    border-color: #EAECF0;
    border-radius: 8px;
}
.datasheet-grp div[role="gridcell"]{
    padding: 0;
}
/* End Review Budget Specific */


div[role="columnheader"][aria-colindex="1"] {
  border-top-left-radius: 8px;
}
div[role="columnheader"]:last-child{
  border-top-right-radius: 8px;
}
.rdg-cell{
  border-width: .5px .5px .5px .5px;
  border-color: #EAECF0;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  width: 100%;
}
.selected-row .rdg-cell {
  border-top-color: #F185E9;
  border-bottom-color: #F185E9;
  border-top-width: 2px;
  border-bottom-width: 2px;
}
.selected-row .rdg-cell[aria-colindex="1"] {
  border-left-color: #F185E9;
  border-left-width: 2px;
}
.selected-row .rdg-cell[aria-colindex="1"] {
  &[aria-selected='true'] {
    outline: inherit;
  }
}
.selected-row .rdg-cell:last-child{
  border-right-color: #F185E9;
  border-right-width: 2px;
}
.selected-row-data{
  background-color: #2DCEBA30;
  outline-width: 2px;
  z-index: 999;
  outline-color: #2DCEBA;
  outline-style: solid;
  outline-offset: -2px;
  
}
.selected-row-data div[aria-colindex="1"]{
  border-left-color: #2DCEBA;
  border-left-width: 2px;
}
.selected-row-data div[aria-colindex="1"]{
  &[aria-selected='true'] {
    outline: inherit;
  }
}

.selected-row-data .rdg-cell::before{
  border-right-color: #2DCEBA;
  border-right-width: 2px;
  outline-width: 2px;
  outline-color: #2DCEBA;
}
.selected-row-data .rdg-cell::after{
  border-right-color: #2DCEBA;
  border-right-width: 2px;
  outline-width: 2px;
  outline-color: #2DCEBA;
}
.selected-row-data{
  outline-width: 2px;
  outline-color: #2DCEBA;
}
 .rdg .selected-row-data:nth-of-type(1) .rdg-cell {
  border-top-color: #2DCEBA;
  border-top-width: 2px;
}
.selected-column {
  border: 2px solid #F48E2F;
  border-width: 1px 2px;
  border-top-color: #EAECF0;
  border-bottom-color: #EAECF0;
}
.selected-column[role='columnheader']{
  border-top-color: #F48E2F;
  border-top-width: 2px;
}
.selected-column {
  &[aria-selected='true'] {
    outline: solid 2px #F48E2F;
  }
}
.selected-column-header {
  border-bottom: 2px solid red;
}
.horizontal-line{
  border-width: 0;
  height: 1px;
  background: #E9EAEC;
}
.rdg-row > *:not(:first-child) {
  &[aria-selected='true'] {
    border-radius: 12px;
    outline-color: #766CE4;
  }
}
.rdg-cell:first-child  {
  &[aria-selected='true'] {
    border-radius: 0px;
    outline-color: transparent;
  }
}
.rdg-cell:nth-child(2){
  border-radius: 0px !important;
    outline-color: transparent !important;
}
.dollar-only-input input:focus{
  outline: none;
  background-color: transparent;
}

