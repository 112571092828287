@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  [contentEditable="true"]:empty:not(:focus):before {
    content: attr(data-placeholder);
    color: #aaa;
    cursor: text;
    pointer-events: none;
  }
}

@layer base {
  .text-gradient-purple-4 {
    @apply bg-gradient-purple-4;
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

body {
  margin: 0;
  padding: 0;
  font-family: "Plus Jakarta Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow: hidden;
}

input[type="search"]::-webkit-search-cancel-button {
  cursor: pointer;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 1px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #f1f1f1;

  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition:
    background-color 0s 600000s,
    color 0s 600000s !important;
}

.apexcharts-tooltip.apexcharts-theme-light {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.Toastify__toast-body {
  padding: 0 !important;
  margin: 0 !important;
}

.Toastify__toast {
  padding: 0 !important;
  margin: 0 !important;
}

.Toastify__progress-bar--success {
  background-color: #00c853 !important;
}

.Toastify__progress-bar--error {
  background-color: red !important;
}

.Toastify__progress-bar--info {
  background-color: #f0b226 !important;
}

.Toastify__progress-bar--wrp {
  height: 3px !important;
}
.Toastify__close-button {
  display: none;
}

/*Sticky last column in table*/
.sticky-column {
  position: sticky;
  right: 0;
  min-width: 100px;
}

.sticky-column:before {
  content: "\00a0";
  display: block;
  position: absolute;
  background: #fff;
  top: 0;
  right: -1px;
  bottom: 0;
  left: 0;
  z-index: -1;
  box-shadow: -7px 0 69px 0 rgba(0, 0, 0, 0.11);
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.sticky-column-left {
  position: sticky;
  left: 0;
  background: white;
  z-index: 1;
}
/** Sticky last column in table **/

.\[\&\:\:-webkit-scrollbar\]\:h-2::-webkit-scrollbar {
  height: 0.2rem !important;
}
::-webkit-scrollbar-track {
  background-color: transparent !important;
}

.apexcharts-canvas {
  background-color: #fff !important;
  border-radius: 16px;
}

.chartContainer {
  /* width: calc(100% - 60px); */
  transition: width 0.2s ease-in-out;
}

.chartContainerOpenChat {
  /* width: calc(100% - 331px); */
  transition: width 0.2s ease-in-out;
}

.chatAssistantContainerClose {
  width: 60px;
  transition: width 0.2s ease-in-out;
}

.chatAssistantContainer {
  width: 331px;
  transition: width 0.2s ease-in-out;
}

.isVisible {
  transition: all 0.5s linear;
  width: 100%;
  opacity: 1;
  overflow: visible;
  visibility: visible;
}

.isClosed {
  opacity: 0;
  display: none;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.3s linear;
}

@keyframes barGrow {
  0% {
    height: 0;
  }
  100% {
    height: var(--bar-height);
  }
}

.bar-animation {
  animation: barGrow 0.5s ease-in-out forwards;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.blinkAiChat {
  animation: blink 3s linear infinite;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rotateStar {
  animation: rotate 2s linear infinite;
  transform-origin: center;
}

.logoTextStyle {
  padding-left: 12px;
  color: #38214b;
  font-family: "Plus Jakarta Sans";
  font-size: 24.538px;
  font-style: normal;
  font-weight: 800;
  line-height: 51.21px;
  letter-spacing: -0.245px;
}

.dashed-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='21' ry='21' stroke='%23C8CAD0' stroke-width='3' stroke-dasharray='7%2c 8' stroke-dashoffset='71' stroke-linecap='square'/%3e%3c/svg%3e");
  border-radius: 21px;
}

.overflow-y-scroll::-webkit-scrollbar {
  width: 4px;
}

.overflow-y-scroll::-webkit-scrollbar-track {
  background: #f1f0fc;
}

.overflow-y-scroll::-webkit-scrollbar-thumb {
  background: #9189e9;
  border-radius: 4px;
}

.react-flow__node-default {
  padding: 0 !important;
  border: none !important;
  box-shadow: 0px 2px 15px 0px rgba(0, 0, 0, 0.1) !important;
}

.swiper {
  width: 100%;
  margin-left: 0;
  margin-right: 0;
}

.swiper-slide {
  background-position: center;
  background-size: cover;
  width: 90%;
  overflow: hidden;
}

.swiper-wrapper {
  width: 95%;
  margin-left: -50px;
}

.swiper-slide .coverflow-slide-content {
  transition: 0.3s all ease;
}

.swiper-slide-visible + .swiper-slide .coverflow-slide-content {
  opacity: 1;
  transition: 0.3s all ease;
}

.swiper-slide-active .coverflow-slide-content {
  background: linear-gradient(90deg, #e534d5 0%, #6659ff 100%);
}

.mySwiper {
  width: 100%;
}
#overlay {
  position: fixed; /* Sit on top of the page content */
  display: block;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
  backdrop-filter: blur(4.199999809265137px);
  z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  justify-content: center;
  align-items: center;
}
#overlay-general {
  position: fixed; /* Sit on top of the page content */
  display: block;
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #1f222840; /* Black background with opacity */
  backdrop-filter: blur(4.199999809265137px);
  z-index: 999; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
  display: flex;
  justify-content: center;
  align-items: center;
}

.ps-sidebar-container {
  border-right: 2px solid #f7f9fb !important;
}

.gradient-box {
  --Gradients-G2: linear-gradient(90deg, #E534D5, #6659FF);
  transform-style: preserve-3d;
  background: var(--Gradients-G2);
  border: 1px solid transparent;
  background-clip: border-box;
  box-shadow: 0px 8px 55px 0px rgba(137, 18, 229, 0.24);
  z-index: 40;

}
.gradient-schedule-post-tab{
    background: linear-gradient(
      90deg,
      #f0f0f8 0%,
      rgba(240, 241, 243, 0.25) 29.5%,
      #eeeef6 98%
    );
}
.tooling-bg {
  background: linear-gradient(180deg, #D6BDFF 0%, #9C93FA 100%)
}

