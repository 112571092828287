.embla {
  max-width: 48rem;
  margin: auto;
}
.embla__viewport {
  overflow: hidden;
}
.embla__container {
  display: flex;
  touch-action: pan-y pinch-zoom;
  margin-left: calc(1rem * -1);
}
.embla__slide {
  transform: translate3d(0, 0, 0);
  flex: 0 0 100%;
  min-width: 0;
  padding-left: 1rem;
}

.tab-indicator div {
  margin: 0 4px;
  height: 8px;
  border-radius: 4px;
  transition: background-color 0.3s ease;
}

.tab-indicator div:hover {
  background-color: #a29bfe;
}

.custom-text {
  color: #1F2937; /* Text color */
  font-size: 20px; /* Set font size */
  font-weight: 600; /* Equivalent to font-semibold */
  text-decoration: underline; /* Underline text */
  transition: all 0.2s ease-in-out; /* Smooth transition */
  text-decoration: none; 
}

.custom-text:hover {
  color: #766CE4; /* Change text color on hover */
  font-weight: 700; /* Equivalent to font-bold */
  text-decoration: underline 
}
