.monthBudgetCard {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      154deg,
      #fbdefc 8.25%,
      #d2c2f5 56.23%,
      #e9e3fd 71.19%,
      #a8a1f6 88.53%
    );
    border-radius: 16px;
    opacity: 0;
    transition: all 0.3s linear;
    z-index: 0;
  }

  &:hover {
    &::before {
      opacity: 1;
    }

    .monthBudgetCard__arrow {
      rotate: -45deg;
      outline: none;
      &:focus {
        outline: none;
      }
    }
  }

  .monthBudgetCard__arrow {
    transition: all 0.3s linear;
  }
}
