@use '../../../../../../styles' as *;

.controllers {
  @include standartBlock($width: '327px', $direction: row);
  position: absolute;
  bottom: 10px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 8px 12px;
  border-radius: 8px;
  background: #fff;
  box-shadow: 0px 2px 4px 0px rgba(16, 24, 40, 0.1);
  z-index: 1000;
  gap: 12px;

  &__btn {
    @include standartBlock($width: '24px', $justify: center);
    height: 24px;
  }

  &__line {
    width: 1px;
    height: 23px;
    background: #e8e9f0;
  }

  select {
    background: none;
  }
}
