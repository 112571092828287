.myContainer {
    display: flex;
    justify-content: space-between;
    height: calc(100vh  - 120px);
    flex-direction: column;
    margin-top: 24px;
    padding: 0 14.063vw;
}
.myCont {
    display: flex;
    /* justify-content: space-between; */
    height: calc(100vh  - 120px);
    width: 100%;
    /* gap: 2.5vw; */
    /* flex-direction: column; */
    /* margin-top: 24px; */
    /* padding: 0 2rem 0 0; */
}

/* Assistant UI styles */
.aui-thread-root {
  display: flex;
  flex-direction: column;
  gap: 24px;
  /* padding: 24px; */
  height: 100%;
  background-color: #FFFFFF;
}

.aui-message-group-root {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.aui-assistant-message-content .aui-text{
    display: none;
}

.aui-assistant-message-root {
  /* display: flex; */
  gap: 12px;
  align-items: flex-start;
  align-self: flex-start;
  /* max-width: 75%; */
  background: none !important;
  float: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

.aui-assistant-message-root .aui-message-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #F1F0FC;
  display: flex;
  align-items: center;
  justify-content: center;
}

.aui-assistant-message-root .aui-message-content {
  background: #F7F7F8;
  border-radius: 0px 12px 12px 12px;
  padding: 16px;
  color: #1F2228;
  font-size: 14px;
  line-height: 150%;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.aui-user-message-root {
  display: flex !important;
  gap: 12px;
  align-items: flex-start;
  align-self: flex-end;
  max-width: 75%;
  flex-direction: row-reverse;
}

.aui-user-message-root .aui-message-avatar {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #766CE4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
}

.aui-user-message-root .aui-message-content {
  background: #F1F0FC;
  border-radius: 12px 0px 12px 12px;
  padding: 16px;
  color: #1F2228;
  font-size: 14px;
  line-height: 150%;
  font-family: "Plus Jakarta Sans", sans-serif;
}

/* Custom composer styles */
.custom-composer {
  position: relative;
  padding: 24px;
  border-top: 1px solid #E9EAEC;
  background: white;
}

.custom-composer textarea {
  width: 100%;
  min-height: 56px;
  padding: 16px;
  background: #FFFFFF;
  border: 1px solid #E9EAEC;
  border-radius: 12px;
  font-size: 14px;
  line-height: 150%;
  color: #1F2228;
  font-family: "Plus Jakarta Sans", sans-serif;
  resize: none;
}

.custom-composer textarea::placeholder {
  color: #717684;
}

.custom-composer textarea:focus {
  outline: none;
  border-color: #766CE4;
}

.custom-composer button {
  position: absolute;
  right: 40px;
  bottom: 36px;
  width: 32px;
  height: 32px;
  background: #766CE4;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border: none;
  color: white;
}

.custom-composer button:disabled {
  background: #E9EAEC;
  cursor: not-allowed;
}

/* Tool UI styles */
.data-sources-tool-ui {
  margin: 8px 0;
  transition: all 0.2s ease-in-out;
}

.data-sources-tool-ui .animate-spin {
  animation: spin 1s linear infinite;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}