@use '../../../../../../styles' as *;

.budgets {
  @include standartBlock($align: flex-start, $justify: flex-start);
  padding: 12px 16px;
  border-radius: 19px;
  background: #fff;

  &__top {
    @include standartBlock($direction: row);
    padding: 0 0 12px 0;
    border-bottom: 1px solid #f7f7f8;

    &__title {
      margin: 0;
      font-size: 20px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
    }

    &__filterBlock {
      @include standartBlock($width: auto, $direction: row);
      color: #717684;
      text-align: center;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: normal;
      border: none;
      gap: 6px;

      &__count {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 50%;
        color: #fff;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: 1;
        background: #766ce4;
      }
    }

    .filledFilter {
      color: #766ce4;

      svg {
        color: #766ce4;
        transition: all 0.3s linear;
      }
    }
  }

  &__budget {
    position: relative;
    width: 100%;
    display: grid;
    grid-template-columns: 30.2% 1fr 30.2%;
    margin: 16px 0 20px 0;

    &__cell {
      @include standartBlock($align: flex-start, $justify: center);
      gap: 4px;

      &:nth-child(2) {
        align-items: center;
        .allocationSection__item__budget__cell__title {
          color: $mainActive;
          text-align: center;
          font-size: 16px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
      }

      &:nth-child(3) {
        align-items: flex-end;
      }

      &__title {
        color: #2e323a;
        text-overflow: ellipsis;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      span {
        overflow: hidden;
        color: #5e636e;
        text-overflow: ellipsis;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
      }
    }
  }

  &__chart {
    @include standartBlock();
    margin-top: 4px;

    &::before {
      content: '';
      position: absolute;
      left: 0%;
      width: 30.2%;
      height: calc(100% - 14px);
      background: linear-gradient(
        90deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 52%,
        rgba(138, 137, 137, 0.15) 100%
      );
      z-index: 11;
    }

    &::after {
      content: '';
      position: absolute;
      right: 0%;
      width: 30.2%;
      height: calc(100% - 14px);
      background: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(255, 255, 255, 0.1) 52%,
        rgba(138, 137, 137, 0.15) 100%
      );
      z-index: 11;
    }

    &__noData {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 30.2%;
      height: 100%;
      left: 0;
      top: 0;
      padding: 0 16px 0 0;
      background: white;
      z-index: 10;

      h3 {
        color: #9a91a8;
        text-align: center;
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: normal;
        margin: 16px 0 4px 0;
      }

      p {
        color: #9a91a8;
        text-align: center;

        font-family: 'Plus Jakarta Sans';
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        margin: 0;
      }
    }

    &__compare {
      position: relative;
      width: 100%;
      display: grid;
      grid-template-columns: 30.2% 1fr 30.2%;
      margin: 0 0 24px 0;

      &__left {
        @include standartBlock($direction: row, $justify: flex-end);
        height: 28px;
        color: #333740;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 4px;

        &__progress {
          position: relative;
          display: flex;
          height: 28px;
          align-items: center;
          justify-content: flex-start;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          background: #f185e9;
          color: #fff;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0 0 0 6px;
          z-index: 9;
        }

        span {
          display: flex;
        }
      }

      &__central {
        @include standartBlock();
        color: #2e323a;
        text-align: center;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
      }

      &__right {
        @include standartBlock($direction: row, $justify: flex-start);
        height: 28px;
        color: #333740;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        gap: 4px;

        &__progress {
          position: relative;
          display: flex;
          height: 28px;
          align-items: center;
          justify-content: flex-end;
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          background: #7b8df4;
          color: #fff;
          font-style: normal;
          font-weight: 500;
          line-height: normal;
          padding: 0 6px 0 0;
        }

        span {
          display: flex;
        }
      }
    }
  }

}
