.quill {
  font-family: "Plus Jakarta Sans" !important;
  @apply p-6 text-[#464A53] border-none text-sm [&_h2]:text-xl [&_h2]:text-black [&_h2]:font-bold [&_h2]:mb-4 [&_h5]:text-black [&_h5]:font-bold [&_h5]:mb-2 [&_ol]:mb-5 [&_ul]:mb-4 [&_ol]:list-disc [&_ol]:space-y-3 [&_ul]:space-y-3 [&_p]:pb-5 p-0;
}

.ql-toolbar {
  border: none !important;
  border-bottom: 1px solid var(--Neutrals-Neutrals---100, #e9eaec) !important;
  padding: 8px 56px !important;
}

.ql-picker-label {
  color: #766ce4;
}

.ql-active {
  color: #766ce4 !important;
}

.ql-active .ql-stroke {
  stroke: #766ce4 !important;
}

.ql-picker-item:hover {
  stroke: #766ce4 !important;
  color: #766ce4 !important;
}

.ql-picker-item:hover .ql-stroke {
  stroke: #766ce4 !important;
}

.ql-picker-label:hover {
  color: #766ce4 !important;
}

.ql-picker-label:hover .ql-stroke {
  stroke: #766ce4 !important;
}

.ql-picker .ql-picker-label .ql-stroke {
  stroke: #766ce4;
}

.ql-align .ql-picker-label .ql-stroke {
  stroke: #444;
}

.ql-formats button:hover .ql-stroke {
  stroke: #766ce4 !important;
}

.ql-formats button:hover .ql-fill {
  fill: #766ce4 !important;
}

.ql-formats {
  border-right: 1px #e9eaec !important;
}

.ql-container {
  border: none !important;
  /* margin-top: 32px !important; */
  height: calc(100vh - 189px) !important;
  overflow: auto;
}

.ql-editor {
  padding: 0px 56px 32px 56px !important;
  font-family: "Plus Jakarta Sans" !important;
  --scrollbar-thumb: #4b5563 !important;
  --scrollbar-track: transparent !important;
  scrollbar-width: thin;
  scrollbar-color: var(--scrollbar-thumb, initial) var(--scrollbar-track, initial);
}

.ql-snow .ql-editor h1 {
  font-size: 1.5em !important;
  font-weight: 600;
  color: black;
  margin-top: 30px;
}

.ql-snow a {
  color: #766ce4 !important;
}

.ql-snow .ql-editor h2 {
  font-size: 1.25em !important;
  font-weight: 600;
  color: #333740;
  margin-top: 20px;
}

.ql-editor p {
  font-size: 16px;
  font-weight: 400;
  color: #333740;
  margin-top: 10px !important;
}

.ql-editor li {
  font-size: 16px;
  font-weight: 400;
  color: #464a53;
}

.ql-editor ol {
  margin-top: 15px !important;
}

.ql-editor ul {
  margin-top: 15px !important;
}
