@use '../../../styles' as *;

.budgetIntelligence {
  &__switcher {
    @include standartBlock($direction: row, $justify: center);
    // width: 326px !important;
    width: fit-content;
    align-items: center;
    padding: 6px;
    border-radius: 9px;
    border: 1px solid #e9eaec;
    background: #f7f7f8;
    gap: 6px;

    button {
      @include standartBlock($direction: row, $width: auto);
      align-items: center;
      justify-content: center;
      padding: 8px 10px;
      gap: 10px;
      font-family: 'Plus Jakarta Sans';
      font-size: 14px;
      font-weight: 600;
      line-height: normal;
      color: $mainActive;
      border-radius: 6px;
      background: #fff;
      box-shadow: 0px 0px 4px 0px rgba(34, 32, 35, 0.13);
    }

    .passive {
      color: $passive;
      background: none;
      box-shadow: none;

      svg {
        color: $passive;
      }
    }
  }
}
