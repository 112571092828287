.text-editor-wrapper .quill {
  font-family: "Plus Jakarta Sans" !important;
  @apply p-6 text-[#464A53] border-none text-sm [&_h2]:text-xl [&_h2]:text-black [&_h2]:font-bold [&_h2]:mb-4 [&_h5]:text-black [&_h5]:font-bold [&_h5]:mb-2 [&_ol]:mb-5 [&_ul]:mb-4 [&_ol]:list-disc [&_ol]:space-y-3 [&_ul]:space-y-3 [&_p]:pb-5 p-0 ;
}

.text-editor-wrapper .ql-toolbar {
    border: none !important;
    border-bottom: 1px solid var(--Neutrals-Neutrals---100, #E9EAEC) !important;
    padding: 8px 16px !important;

}

.text-editor-wrapper .ql-picker-label {
    color: #766CE4;
}

.text-editor-wrapper .ql-active {
    color: #766CE4 !important;
}

.text-editor-wrapper .ql-active .ql-stroke {
    stroke: #766CE4 !important;
}

.text-editor-wrapper .ql-picker-item:hover {
    stroke: #766CE4 !important;
    color: #766CE4 !important;
}

.text-editor-wrapper .ql-picker-item:hover .ql-stroke {
    stroke: #766CE4 !important;
}


.text-editor-wrapper .ql-picker-label:hover {
    color: #766CE4 !important;
}

.text-editor-wrapper .ql-picker-label:hover .ql-stroke {
    stroke: #766CE4 !important;
}

.text-editor-wrapper .ql-picker .ql-picker-label .ql-stroke {
    stroke: #766CE4;
}

.text-editor-wrapper .ql-align .ql-picker-label .ql-stroke {
    stroke: #444;
}

.text-editor-wrapper .ql-formats button:hover .ql-stroke {
    stroke: #766CE4 !important;
}

.text-editor-wrapper .ql-formats button:hover .ql-fill {
    fill: #766CE4 !important;
}

.text-editor-wrapper .ql-formats {
    border-right: 1px #E9EAEC !important;
}

.text-editor-wrapper .ql-container {
    border: none !important;
    /* margin-top: 32px !important; */
    height: calc(100vh - 189px) !important;
    overflow: auto;
}

.text-editor-wrapper .ql-editor {
    padding: 105px 16px 16px 16px !important;
    font-family: "Plus Jakarta Sans" !important;
}

.text-editor-wrapper .ql-snow .ql-editor h1 {
    font-size: 1.5em !important;
    font-weight: 600;
    color: black;
    margin-top: 30px;
}

.text-editor-wrapper .ql-snow a {
    color: #766CE4 !important;
}

.text-editor-wrapper .ql-snow .ql-editor h2 {
    font-size: 1.25em !important;
    font-weight: 600;
    color: #333740;
    margin-top: 20px;
}

.text-editor-wrapper .ql-editor p {
    font-size: 16px;
    font-weight: 400;
    color: #333740;
    margin-top: 10px !important; 
}

.text-editor-wrapper .ql-editor li {
    font-size: 16px;
    font-weight: 400;
    color: #464A53;
}

.text-editor-wrapper .ql-editor ol {
    margin-top: 15px !important;
}

.text-editor-wrapper .ql-editor ul {
    margin-top: 15px !important;
}
