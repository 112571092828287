@use '../../../../../styles' as *;

.strategy {
  @include standartBlock();
  height: 600px;
  background: #f9f9f9;
  gap: 27px;

  &__title {
    width: 100%;
    margin: 0;
    color: #000;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 27px;
    letter-spacing: -0.15px;
    text-align: left;
  }

  &__parent {
    width: 400px !important;
    height: auto;
    background: #fff;
    border: none;
    border-radius: 16px !important;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);

    &__donutChart {
      position: relative;
      width: 363px;
      height: 200px;
      margin: 0 auto 29px auto;
    }
  }

  &__child {
    width: 310px !important;
    height: 130px;
    border-radius: 12px !important;
    background: #fff;
    box-shadow: 0px  4px 4px -2px rgba(16, 24, 40, 0.16);

    &__nodeLabel {
      @include standartBlock();
      width: 100%;
      height: 100%;
      padding: 16px;

      &__top {
        @include standartBlock($direction: row, $align: flex-start);

        &__leftBLock {
          @include standartBlock($direction: row, $width: auto);
          gap: 12px;

          &__icon {
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 36px;
            height: 36px;
            border-radius: 8px;
            background: #dbe6ff;
          }

          &__title {
            margin: 0;
            color: #14191a;
            font-size: 18px;
            font-style: normal;
            font-weight: 700;
            line-height: 28px;
          }
        }

        &__rightBLock {
          position: relative;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }

      &__bottom {
        @include standartBlock($direction: row);

        &__item {
          @include standartBlock($width: auto, $align: flex-start);
          gap: 4px;
          margin-top: 6px;

          &__title {
            margin: 0;
            color: #5e636e;
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }

          &__info {
            display: flex;
            color: #1f2228;
            font-size: 14px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            gap: 4px;
            text-align: left;

            &__percentage {
              display: flex;
              padding: 2px 6px;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              background: #daefff;
              color: #4b80fc;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 700;
              line-height: normal;
            }
          }
        }
      }
    }
  }
}
