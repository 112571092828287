.container{
    width:100%;
    border-radius: 12px;
    /* background-color: #F7F7F8; */
    /* padding: 24px; */
    border: 1px solid #E9EAEC;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-top: 20px;
}
.cont{
    width:90%;
    border-radius: 12px;
    /* background-color: #F7F7F8; */
    /* padding: 24px; */
    border: 1px solid #E9EAEC;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.05);
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 20px 2rem 0 2rem;
    /* margin-top: 20px; */
}
.custom-textarea {
    background-color: #FFFFFF;
    width: 100%;
    font-size: 14px;
    padding: 24px;
    font-family: "Plus Jakarta Sans", sans-serif;
    resize: none; /* Disable manual resizing */
    overflow: hidden; /* Hide scrollbar when not needed */
    min-height: 45px; /* Set a minimum height */
    transition: height 0.2s ease-in-out; /* Smooth height adjustment */
    outline: none;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  .custom-textarea::placeholder {
    color: #717684; /* Light gray placeholder */
    font-size: 14px; /* Decrease font size */
    font-family: "Plus Jakarta Sans", sans-serif;
  }

  .btnFlx{
    display: flex;
    justify-content: space-between;
    background-color: #F7F7F8;
    align-items: center;
    border-top: 1px solid #E9EAEC;
    border-bottom-right-radius: 12px;
    border-bottom-left-radius: 12px;
    /* margin-top: 30px; */
    padding: 12px 24px;
  }

  .dflex{
    display: flex;
    align-items: center;
    gap:12px
  }
  
  
  .custom-send-button {
    /* Your custom styles for the send button */
     height: 32px;
     width: 32px;
     background-color: #766CE4;
     color: #FFFFFF;
     border-radius: 50%;
     display: flex;
     justify-content: center;
     align-items: center;
     cursor: pointer;
  }
  
  .custom-send-button:hover {
    background-color: #766CE4;
  }

  .disabled {
    pointer-events: none;
    opacity: 0.5;
  }


  .aui-avatar-root{
    display: none;
  }

  /* .aui-assistant-message-root{
    float: left;
    background-color:  #F7F7F8;
    padding: 6px 16px;
    border-radius: 12px;
    max-width: 700px;
    margin-top: 10px;
    margin-right: 10px;
  } */

  .aui-assistant-action-bar-root{
    display: none;
  }

  