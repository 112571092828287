@use '../../../../../styles' as *;

.allocationSection {
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-auto-flow: dense;
  gap: 24px;

  &__item {
    @include standartBlock($align: flex-start);
    padding: 12px 16px;
    border-radius: 19px;
    background: #fff;

    &:nth-child(2) {
      overflow: hidden;

      &::before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: #fcfcfc;
        opacity: 0.8;
        z-index: 11;
      }

      .allocationSection__item__top {
        border-bottom: none;
      }
    }

    &__comingSoon {
      display: flex;
      position: absolute;
      top: 8px;
      left: 12%;
      padding: 5px 12px;
      justify-content: center;
      align-items: center;
      border-radius: 17px;
      background: linear-gradient(87deg, #ffe145 -0.32%, #ffc8a0 95.01%);
      color: #14191a;

      font-size: 16px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      z-index: 11;
    }

    &__modal {
      @include standartBlock($direction: row);
      width: 74.4%;
      position: absolute;
      padding: 35px 40px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 23px;
      border: 2px dashed #c0bbf3;
      background: var(--Background-Card, #fcfcfc);
      box-shadow: 0px 6px 32px 0px rgba(0, 0, 0, 0.08);
      z-index: 11;

      &__leftSide {
        @include standartBlock($width: 61%);

        h3 {
          color: #9ea2ad;
          text-align: center;
          font-size: 18px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          margin: 0;
        }

        p {
          color: #9ea2ad;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 170%;
          margin: 10px 0 24px 0;
        }

        button {
          @include standartBlock($width: auto);
          padding: 10px 24px;
          border-radius: 8px;
          background: #e9eaec;
          color: #9ea2ad;
          text-align: center;
          font-family: 'Plus Jakarta Sans';
          font-size: 14px;
          font-style: normal;
          font-weight: 600;
          line-height: normal;
        }
      }

      &__rightSide {
        @include standartBlock($width: 33.5%);
      }
    }

    &__top {
      @include standartBlock($direction: row);
      padding: 0 0 12px 0;
      border-bottom: 1px solid #f7f7f8;

      &__title {
        margin: 0;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }

      &__filterBlock {
        @include standartBlock($width: auto, $direction: row);
        color: #717684;
        text-align: center;
        font-size: 13px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        border: none;
        gap: 6px;

        &__count {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          color: #fff;
          font-size: 13px;
          font-style: normal;
          font-weight: 500;
          line-height: 1;
          background: #766ce4;
        }
      }

      .filledFilter {
        color: #766ce4;

        svg {
          color: #766ce4;
          transition: all 0.3s linear;
        }
      }
    }

    &__metrics {
      @include standartBlock($justify: flex-start);
      height: 100%;
      gap: 16px;

      &__chart {
        @include standartBlock();
        width: 100%;
        border: 1px solid #e9eaec;
        border-radius: 16px;
        background: #fff;
        padding: 36px 24px 16px 24px;

        &__top {
          @include standartBlock($direction: row, $align: flex-start);
          position: absolute;
          top: 16px;
          padding: 0 24px;

          &__left {
            @include standartBlock($width: 80%, $align: flex-start);
            gap: 16px;

            &__title {
              color: #5e636e;
              font-family: 'Plus Jakarta Sans';
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 18px;
              margin: 0;
            }

            &__info {
              @include standartBlock($direction: row, $justify: flex-start);
              gap: 12px;

              span {
                color: #14191a;
                font-size: 24px;
                font-style: normal;
                font-weight: 600;
                line-height: 30px;
                letter-spacing: -0.18px;
              }

              &__trend {
                @include standartBlock($direction: row, $width: auto);
                color: #5e636e;
                font-size: 12px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
                gap: 2px;

                svg {
                  color: rgba(250, 146, 153, 1);
                  transform: rotate(90deg);
                }
              }
            }
          }

          &__right {
            @include standartBlock($width: auto, $align: flex-start);

            button {
              @include standartBlock($justify: center);
              width: 56px;
              height: 56px;
              border-radius: 16px;
              background: #dee9ff;
            }
          }
        }
      }
    }
  }
}
