.expandBtn {
  position: absolute;
  display: flex;
  // visibility: hidden;
  justify-content: center;
  align-items: center;
  top: 12px;
  width: 24px;
  height: 46px;
  border-radius: 360px;
  border: 1.148px solid #e8e9f0;
  background: #fff;
  box-shadow: 2px 0px 42px 0px rgba(18, 15, 52, 0.17);
  z-index: 30;
  transition: all 0.18s ease-out;

  &:hover {
    visibility: visible;
  }
}

// .ps-sidebar-root {
//   &:hover ~ .expandBtn {
//     visibility: visible;
//   }
// }
